import React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import underlinedHero from "../images/home/underlined-hero.png";
import OMG from "../images/home/omg.png";
import { useTranslation } from "react-i18next";
import { Hero, InfoSection, ContactSection } from "../styles/pages/usablity";
import pieza1 from "../images/home/piezas-1.png";
import lapiezaEco from "../images/carbon/lapieza-eco.png";

const Pledge = () => {
  const { t } = useTranslation("ourPledge");

  return (
    <Layout>
      <Seo
        title="Queremos que digas “encontré el trabajo de mis sueños”"
        description="Buscamos promover con nuestros productos, la idea de que todos pueden tener el trabajo de sus sueños."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Hero>
        <div className="main-landing-container-row">
          <article>
            <h1>{t("usability")}</h1>
            <h2>
              {t("title")}
              <span>
                <img src={underlinedHero} alt="Nuestro equipo" />
              </span>
            </h2>
            <p>{t("simpleDesc")}</p>
          </article>
          <figure>
            <img src={pieza1} alt="Figura de LaPieza" />
          </figure>
        </div>
      </Hero>
      <InfoSection>
        <div className="info-section main-landing-container-column">
          <h2>{t("usability")}</h2>
          <p>{t("desc")}</p>
          <div className="info-section-list">
            <article>
              <h5>{t("infoFirstTitle")}</h5>
              <p>{t("infoFirstDesc")}</p>
            </article>
            <article>
              <h5>{t("infoSecondTitle")}</h5>
              <p>{t("infoSecondDesc")}</p>
            </article>
            <article>
              <h5>{t("infoThirdTitle")}</h5>
              <p>{t("infoThirdDesc")}</p>
            </article>
            <article>
              <h5>{t("infoFourthTitle")}</h5>
              <p>{t("infoFourthDesc")}</p>
            </article>
          </div>
        </div>
      </InfoSection>
      <ContactSection>
        <div className="contact-section main-landing-container-row">
          <article>
            <h5>{t("contactTitle")}</h5>
            <p>{t("contactDescription")}</p>
            <p>
              {t("contactMessage")}{" "}
              <a href="mailto:carlos@lapieza.io">carlos@lapieza.io</a>
            </p>
            <p>{t("contactMessage2")}</p>
            <Link className="contact-button" to="/contact">
              {t("contactTeam")}
            </Link>
          </article>
          <figure>
            <img src={lapiezaEco} alt="Neutralidad de carbono | LaPieza" />
          </figure>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
